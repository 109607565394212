import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    domainRESTPrefix: '/api/v1',
    currentDomain: `${window.location.protocol}//${window.location.hostname}`,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getCurrentDomain(state) {
        if (process.env.NODE_ENV == "development") {
            
            var domain = "http://10.0.0.121:3334";// "https://ht-cooking.com";
            if (process.env.VUE_APP_DOMAIN != undefined && process.env.VUE_APP_DOMAIN != "") 
                domain = "http://" + process.env.VUE_APP_DOMAIN;

            return domain + state.domainRESTPrefix;
        }
        else {
            console.log(state.currentDomain + state.domainRESTPrefix);
            return state.currentDomain + state.domainRESTPrefix;
        }
    },      
  }
})
