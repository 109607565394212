import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

Vue.component("image-icon", {
    template: `<v-img :src="imgsrc" max-width="32"></v-img>`,
    props: ["imgsrc"]
});

Vue.component("my-icon", {
    template: `<svg height="24" width="24">
          <circle cx="12" cy="12" r="9" fill="red" />
      </svg>`
});



export default new Vuetify({
    theme: { dark: true },
    icons: {
        iconfont: 'mdi',
        values: {
            myicon: {
                component: "my-icon"
            },            
            tovicookie: {
                component: "image-icon",
                props: {
                  imgsrc: "@/assets/logo.png"
                }
            },
            tovi_cookie_filled: {
                component: "tovi-cookie-filled"
            },
            tovi_cookie_outline: {
                component: "tovi-cookie-outline"
            },            
        },
    }

});

Vue.component("tovi-cookie-filled", {
    template: `<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 745.2 708.9" overflow="visible" xml:space="preserve">
<g>
   <path fill-rule="evenodd" fill="#FFFFFF" d="M535.6,297.9c23.8,35.5,53.8,46.3,92.9,22.3c1.3,13.4,1.6,27,0.9,40.8
       c-7.7,152.7-137.7,270.3-290.4,262.6C186.3,615.9,68.7,485.9,76.4,333.2C84,180.5,218.5,61.6,371.2,69.3
       c-13.5,42.4-2.1,80,32.4,92.8C372.4,262.4,442.1,328,535.6,297.9L535.6,297.9z"/>
</g>
<g>
   <path d="M259.1,228.5l-31.9,21c-1.7,1.1-2.2,3.4-1.1,5.2l21,31.9c1.1,1.7,3.4,2.2,5.2,1.1l31.9-21c1.7-1.1,2.2-3.4,1.1-5.2
       l-21-31.9C263.1,227.8,260.8,227.4,259.1,228.5z M271.6,260c0.6,0.9,0.4,2.1-0.6,2.7l-17.3,11.3c-0.9,0.6-2.1,0.4-2.7-0.6
       l-11.3-17.3c-0.6-0.9-0.4-2.1,0.6-2.7l17.3-11.3c0.9-0.6,2.1-0.4,2.7,0.6C260.3,242.7,271.6,260,271.6,260z"/>
   <path d="M280.1,229.2c-0.5-0.7-1.5-1-2.3-0.5l-6.9,4.5l1.8,2.8l6.9-4.5C280.4,231.1,280.6,230,280.1,229.2z"/>
   <path d="M283.7,237.7l-6.9,4.5l1.8,2.8l6.9-4.5c0.7-0.5,1-1.5,0.5-2.3C285.5,237.5,284.4,237.2,283.7,237.7z"/>
   <path d="M289.5,246.6l-6.9,4.5l1.8,2.8l6.9-4.5c0.7-0.5,1-1.5,0.5-2.3C291.3,246.4,290.3,246.1,289.5,246.6z"/>
   <path d="M282.2,282.1c0.5,0.7,1.5,1,2.3,0.5c0.7-0.5,1-1.5,0.5-2.3l-4.5-6.9l-2.8,1.8L282.2,282.1z"/>
   <path d="M273.3,287.9c0.5,0.7,1.5,1,2.3,0.5c0.7-0.5,1-1.5,0.5-2.3l-4.5-6.9l-2.8,1.8L273.3,287.9z"/>
   <path d="M264.4,293.8c0.5,0.7,1.5,1,2.3,0.5c0.7-0.5,1-1.5,0.5-2.3l-4.5-6.9l-2.8,1.8L264.4,293.8z"/>
   <path d="M231.3,287c0.5,0.7,1.5,1,2.3,0.5l6.9-4.5l-1.8-2.8l-6.9,4.5C231,285.1,230.8,286.2,231.3,287z"/>
   <path d="M225.4,278c0.5,0.7,1.5,1,2.3,0.5l6.9-4.5l-1.8-2.8l-6.9,4.5C225.1,276.2,224.9,277.3,225.4,278z"/>
   <path d="M219.5,269c0.5,0.7,1.5,1,2.3,0.5l6.9-4.5l-1.8-2.8l-6.9,4.5C219.3,267.3,219,268.3,219.5,269z"/>
   <path d="M229.1,234.1c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3l4.5,6.9l2.8-1.8L229.1,234.1z"/>
   <path d="M238,228.3c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3l4.5,6.9l2.8-1.8L238,228.3z"/>
   <path d="M247,222.4c-0.5-0.7-1.5-1-2.3-0.5s-1,1.5-0.5,2.3l4.5,6.9l2.8-1.8L247,222.4z"/>
</g>
<g>
   <path d="M295.9,413.8l-46.6,7.7c-2.5,0.4-4.2,2.8-3.8,5.3l7.7,46.6c0.4,2.5,2.8,4.2,5.3,3.8l46.6-7.7c2.5-0.4,4.2-2.8,3.8-5.3
       l-7.7-46.6C300.7,415.1,298.4,413.4,295.9,413.8z M294.3,455.6c0.2,1.3-0.7,2.6-2,2.8l-25.2,4.2c-1.3,0.2-2.6-0.7-2.8-2l-4.2-25.2
       c-0.2-1.3,0.7-2.6,2-2.8l25.2-4.2c1.3-0.2,2.6,0.7,2.8,2C290.1,430.5,294.3,455.6,294.3,455.6z"/>
   <path d="M319.2,425.1c-0.2-1.1-1.3-1.9-2.3-1.7l-10,1.7l0.7,4l10-1.7C318.6,427.4,319.4,426.3,319.2,425.1z"/>
   <path d="M319,436.5l-10,1.7l0.7,4l10-1.7c1.1-0.2,1.9-1.3,1.7-2.3C321.2,437.1,320.1,436.4,319,436.5z"/>
   <path d="M321.2,449.5l-10,1.7l0.7,4l10-1.7c1.1-0.2,1.9-1.3,1.7-2.3C323.3,450.1,322.2,449.3,321.2,449.5z"/>
   <path d="M295.1,485.9c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L295.1,485.9z"/>
   <path d="M282.1,488c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L282.1,488z"/>
   <path d="M269.1,490.2c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L269.1,490.2z"/>
   <path d="M235.1,466c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C235.7,463.7,234.9,464.8,235.1,466z"/>
   <path d="M233,452.8c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C233.6,450.7,232.8,451.8,233,452.8z"/>
   <path d="M230.8,439.7c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C231.4,437.7,230.6,438.6,230.8,439.7z"/>
   <path d="M259.2,405.2c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L259.2,405.2z"/>
   <path d="M272.2,403c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L272.2,403z"/>
   <path d="M285.2,400.9c-0.2-1.1-1.3-1.9-2.3-1.7s-1.9,1.3-1.7,2.3l1.7,10l4-0.7L285.2,400.9z"/>
</g>
<g>
   <path fill="#231F20" d="M522.7,422.4l-11.7-20.3c-0.6-1.1-2-1.5-3.1-0.8l-20.3,11.7c-1.1,0.6-1.5,2-0.8,3.1l11.7,20.3
       c0.6,1.1,2,1.5,3.1,0.8l20.3-11.7C522.9,424.9,523.3,423.5,522.7,422.4z M503,429c-0.6,0.3-1.3,0.1-1.7-0.4l-6.3-10.9
       c-0.3-0.6-0.1-1.3,0.4-1.7l10.9-6.3c0.6-0.3,1.3-0.1,1.7,0.4l6.3,10.9c0.3,0.6,0.1,1.3-0.4,1.7C513.9,422.7,503,429,503,429z"/>
   <path fill="#231F20" d="M521.5,435.2c0.5-0.3,0.6-0.9,0.4-1.4l-2.5-4.4l-1.7,1l2.5,4.4C520.4,435.4,521,435.5,521.5,435.2z"/>
   <path fill="#231F20" d="M516.2,437.1l-2.5-4.4l-1.7,1l2.5,4.4c0.3,0.5,0.9,0.6,1.4,0.4C516.3,438.3,516.5,437.6,516.2,437.1z"/>
   <path fill="#231F20" d="M510.5,440.4L508,436l-1.7,1l2.5,4.4c0.3,0.5,0.9,0.6,1.4,0.4C510.6,441.5,510.8,440.9,510.5,440.4z"/>
   <path fill="#231F20" d="M489.1,434.7c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l4.4-2.5l-1-1.7L489.1,434.7z"/>
   <path fill="#231F20" d="M485.9,429.1c-0.5,0.3-0.6,0.9-0.4,1.4s0.9,0.6,1.4,0.4l4.4-2.5l-1-1.7L485.9,429.1z"/>
   <path fill="#231F20" d="M482.6,423.4c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l4.4-2.5l-1-1.7L482.6,423.4z"/>
   <path fill="#231F20" d="M487.9,403.4c-0.5,0.3-0.6,0.9-0.4,1.4l2.5,4.4l1.7-1l-2.5-4.4C489,403.3,488.4,403.1,487.9,403.4z"/>
   <path fill="#231F20" d="M493.6,400.1c-0.5,0.3-0.6,0.9-0.4,1.4l2.5,4.4l1.7-1l-2.5-4.4C494.7,400,494,399.8,493.6,400.1z"/>
   <path fill="#231F20" d="M499.3,396.8c-0.5,0.3-0.6,0.9-0.4,1.4l2.5,4.4l1.7-1l-2.5-4.4C500.3,396.7,499.7,396.5,499.3,396.8z"/>
   <path fill="#231F20" d="M520.3,403.9c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-4.4,2.5l1,1.7L520.3,403.9z"/>
   <path fill="#231F20" d="M523.5,409.6c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-4.4,2.5l1,1.7L523.5,409.6z"/>
   <path fill="#231F20" d="M526.8,415.2c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-4.4,2.5l1,1.7L526.8,415.2z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M548.1,161.8l-46.6,7.7c-2.5,0.4-4.2,2.8-3.8,5.3l7.7,46.6c0.4,2.5,2.8,4.2,5.3,3.8l46.6-7.7
       c2.5-0.4,4.2-2.8,3.8-5.3l-7.7-46.6C553,163.1,550.6,161.4,548.1,161.8z M546.5,203.7c0.2,1.3-0.7,2.6-2,2.8l-25.2,4.2
       c-1.3,0.2-2.6-0.7-2.8-2l-4.2-25.2c-0.2-1.3,0.7-2.6,2-2.8l25.2-4.2c1.3-0.2,2.6,0.7,2.8,2C542.3,178.5,546.5,203.7,546.5,203.7z"
       />
   <path fill="#FFFFFF" d="M571.4,173.1c-0.2-1.1-1.3-1.9-2.3-1.7l-10,1.7l0.7,4l10-1.7C570.8,175.4,571.6,174.3,571.4,173.1z"/>
   <path fill="#FFFFFF" d="M571.2,184.6l-10,1.7l0.7,4l10-1.7c1.1-0.2,1.9-1.3,1.7-2.3C573.4,185.2,572.3,184.4,571.2,184.6z"/>
   <path fill="#FFFFFF" d="M573.4,197.6l-10,1.7l0.7,4l10-1.7c1.1-0.2,1.9-1.3,1.7-2.3C575.6,198.2,574.5,197.4,573.4,197.6z"/>
   <path fill="#FFFFFF" d="M547.4,233.9c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L547.4,233.9z"/>
   <path fill="#FFFFFF" d="M534.4,236.1c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L534.4,236.1z"/>
   <path fill="#FFFFFF" d="M521.4,238.2c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L521.4,238.2z"/>
   <path fill="#FFFFFF" d="M487.4,214c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C487.9,211.7,487.2,212.8,487.4,214z"/>
   <path fill="#FFFFFF" d="M485.2,200.9c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C485.8,198.7,485,199.8,485.2,200.9z"/>
   <path fill="#FFFFFF" d="M483,187.7c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C483.6,185.7,482.8,186.7,483,187.7z"/>
   <path fill="#FFFFFF" d="M511.4,153.2c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L511.4,153.2z"/>
   <path fill="#FFFFFF" d="M524.4,151c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L524.4,151z"/>
   <path fill="#FFFFFF" d="M537.4,148.9c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L537.4,148.9z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M575,79.6L549.6,61c-1.4-1-3.3-0.7-4.3,0.7L526.8,87c-1,1.4-0.7,3.3,0.7,4.3l25.3,18.6
       c1.4,1,3.3,0.7,4.3-0.7l18.6-25.3C576.7,82.5,576.4,80.6,575,79.6z M554.4,98.3c-0.5,0.7-1.5,0.9-2.3,0.4l-13.7-10
       c-0.7-0.5-0.9-1.5-0.4-2.3l10-13.7c0.5-0.7,1.5-0.9,2.3-0.4l13.7,10c0.7,0.5,0.9,1.5,0.4,2.3C564.4,84.6,554.4,98.3,554.4,98.3z"/>
   <path fill="#FFFFFF" d="M580.5,95.9c0.4-0.6,0.3-1.5-0.3-1.9l-5.5-4l-1.6,2.2l5.5,4C579.1,96.7,580,96.6,580.5,95.9z"/>
   <path fill="#FFFFFF" d="M575,101.1l-5.5-4l-1.6,2.2l5.5,4c0.6,0.4,1.5,0.3,1.9-0.3C575.7,102.5,575.5,101.6,575,101.1z"/>
   <path fill="#FFFFFF" d="M569.8,108.2l-5.5-4l-1.6,2.2l5.5,4c0.6,0.4,1.5,0.3,1.9-0.3C570.5,109.5,570.4,108.6,569.8,108.2z"/>
   <path fill="#FFFFFF" d="M540.4,112.7c-0.4,0.6-0.3,1.5,0.3,1.9c0.6,0.4,1.5,0.3,1.9-0.3l4-5.5l-2.2-1.6L540.4,112.7z"/>
   <path fill="#FFFFFF" d="M533.3,107.6c-0.4,0.6-0.3,1.5,0.3,1.9c0.6,0.4,1.5,0.3,1.9-0.3l4-5.5l-2.2-1.6L533.3,107.6z"/>
   <path fill="#FFFFFF" d="M526.3,102.4c-0.4,0.6-0.3,1.5,0.3,1.9c0.6,0.4,1.5,0.3,1.9-0.3l4-5.5l-2.2-1.6L526.3,102.4z"/>
   <path fill="#FFFFFF" d="M522,75c-0.4,0.6-0.3,1.5,0.3,1.9l5.5,4l1.6-2.2l-5.5-4C523.3,74.2,522.4,74.3,522,75z"/>
   <path fill="#FFFFFF" d="M527.2,67.8c-0.4,0.6-0.3,1.5,0.3,1.9l5.5,4l1.6-2.2l-5.5-4C528.5,67.1,527.6,67.3,527.2,67.8z"/>
   <path fill="#FFFFFF" d="M532.4,60.7c-0.4,0.6-0.3,1.5,0.3,1.9l5.5,4l1.6-2.2l-5.5-4C533.7,60.1,532.8,60.1,532.4,60.7z"/>
   <path fill="#FFFFFF" d="M562,58.1c0.4-0.6,0.3-1.5-0.3-1.9c-0.6-0.4-1.5-0.3-1.9,0.3l-4,5.5l2.2,1.6L562,58.1z"/>
   <path fill="#FFFFFF" d="M569.1,63.3c0.4-0.6,0.3-1.5-0.3-1.9c-0.6-0.4-1.5-0.3-1.9,0.3l-4,5.5l2.2,1.6L569.1,63.3z"/>
   <path fill="#FFFFFF" d="M576.2,68.5c0.4-0.6,0.3-1.5-0.3-1.9c-0.6-0.4-1.5-0.3-1.9,0.3l-4,5.5l2.2,1.6L576.2,68.5z"/>
</g>
</svg>`
  });

  Vue.component("tovi-cookie-outline", {
    template: `<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 745.2 708.9" overflow="visible" xml:space="preserve">
<g>
   <path fill="#FFFFFF" d="M353.2,626c-4.8,0-9.6-0.1-14.4-0.4c-74.4-3.8-142.9-36.3-192.8-91.5c-50-55.3-75.4-126.6-71.7-201
       c1.9-37.5,11.3-73.7,28-107.5c16.1-32.5,38.2-61.5,65.7-86c27.4-24.4,58.7-43.2,93.1-55.7c35.5-12.9,72.6-18.5,110.2-16.6l2.6,0.1
       l-0.8,2.5c-13.7,42.8-1.4,78.2,31.2,90.3l1.8,0.7l-0.6,1.8c-14.6,46.8-7.6,88.7,19,115.1c26.1,25.8,66.3,32.4,110.4,18.2l1.4-0.5
       l0.8,1.3c15.3,22.9,32.6,34.3,52.1,34.3c11.8,0,24.5-4.2,38.1-12.5l2.7-1.7l0.3,3.2c1.3,13.5,1.6,27.3,0.9,41.1
       c-3.7,74.4-36.2,142.9-91.5,192.9C488.2,600.7,422.4,626,353.2,626z M357,70.9c-68.5,0-134.1,25.2-186.3,71.7
       C114.8,192.4,82,260.1,78.4,333.3C70.7,484.6,187.7,614,339,621.6c151.4,7.6,280.7-109.3,288.3-260.7c0.6-12.5,0.4-25.1-0.6-37.4
       c-18.4,10.6-35.5,13.9-51,9.7c-15-4-28.7-15.1-41-33c-22.5,7-43.9,8.8-63.8,5.3c-19.3-3.5-36.3-12.1-49.3-24.9
       c-27.4-27.1-34.8-69.8-20.5-117.3c-16.3-6.6-27.9-19-33.6-35.9c-5.5-16.3-5.1-35.7,1-56.2C364.7,71,360.9,70.9,357,70.9z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M259.1,228.5l-31.9,21c-1.7,1.1-2.2,3.4-1.1,5.2l21,31.9c1.1,1.7,3.4,2.2,5.2,1.1l31.9-21
       c1.7-1.1,2.2-3.4,1.1-5.2l-21-31.9C263.1,227.8,260.8,227.4,259.1,228.5z M271.6,260c0.6,0.9,0.4,2.1-0.6,2.7l-17.3,11.3
       c-0.9,0.6-2.1,0.4-2.7-0.6l-11.3-17.3c-0.6-0.9-0.4-2.1,0.6-2.7l17.3-11.3c0.9-0.6,2.1-0.4,2.7,0.6L271.6,260z"/>
   <path fill="#FFFFFF" d="M280.1,229.2c-0.5-0.7-1.5-1-2.3-0.5l-6.9,4.5l1.8,2.8l6.9-4.5C280.4,231.1,280.6,230,280.1,229.2z"/>
   <path fill="#FFFFFF" d="M283.7,237.7l-6.9,4.5l1.8,2.8l6.9-4.5c0.7-0.5,1-1.5,0.5-2.3C285.5,237.5,284.4,237.2,283.7,237.7z"/>
   <path fill="#FFFFFF" d="M289.5,246.6l-6.9,4.5l1.8,2.8l6.9-4.5c0.7-0.5,1-1.5,0.5-2.3C291.3,246.4,290.3,246.1,289.5,246.6z"/>
   <path fill="#FFFFFF" d="M282.2,282.1c0.5,0.7,1.5,1,2.3,0.5c0.7-0.5,1-1.5,0.5-2.3l-4.5-6.9l-2.8,1.8L282.2,282.1z"/>
   <path fill="#FFFFFF" d="M273.3,287.9c0.5,0.7,1.5,1,2.3,0.5c0.7-0.5,1-1.5,0.5-2.3l-4.5-6.9l-2.8,1.8L273.3,287.9z"/>
   <path fill="#FFFFFF" d="M264.4,293.8c0.5,0.7,1.5,1,2.3,0.5c0.7-0.5,1-1.5,0.5-2.3l-4.5-6.9l-2.8,1.8L264.4,293.8z"/>
   <path fill="#FFFFFF" d="M231.3,287c0.5,0.7,1.5,1,2.3,0.5l6.9-4.5l-1.8-2.8l-6.9,4.5C231,285.1,230.8,286.2,231.3,287z"/>
   <path fill="#FFFFFF" d="M225.4,278c0.5,0.7,1.5,1,2.3,0.5l6.9-4.5l-1.8-2.8l-6.9,4.5C225.1,276.2,224.9,277.3,225.4,278z"/>
   <path fill="#FFFFFF" d="M219.5,269c0.5,0.7,1.5,1,2.3,0.5l6.9-4.5l-1.8-2.8l-6.9,4.5C219.3,267.3,219,268.3,219.5,269z"/>
   <path fill="#FFFFFF" d="M229.1,234.1c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3l4.5,6.9l2.8-1.8L229.1,234.1z"/>
   <path fill="#FFFFFF" d="M238,228.3c-0.5-0.7-1.5-1-2.3-0.5c-0.7,0.5-1,1.5-0.5,2.3l4.5,6.9l2.8-1.8L238,228.3z"/>
   <path fill="#FFFFFF" d="M247,222.4c-0.5-0.7-1.5-1-2.3-0.5s-1,1.5-0.5,2.3l4.5,6.9l2.8-1.8L247,222.4z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M295.9,413.8l-46.6,7.7c-2.5,0.4-4.2,2.8-3.8,5.3l7.7,46.6c0.4,2.5,2.8,4.2,5.3,3.8l46.6-7.7
       c2.5-0.4,4.2-2.8,3.8-5.3l-7.7-46.6C300.7,415.1,298.4,413.4,295.9,413.8z M294.3,455.6c0.2,1.3-0.7,2.6-2,2.8l-25.2,4.2
       c-1.3,0.2-2.6-0.7-2.8-2l-4.2-25.2c-0.2-1.3,0.7-2.6,2-2.8l25.2-4.2c1.3-0.2,2.6,0.7,2.8,2C290.1,430.5,294.3,455.6,294.3,455.6z"
       />
   <path fill="#FFFFFF" d="M319.2,425.1c-0.2-1.1-1.3-1.9-2.3-1.7l-10,1.7l0.7,4l10-1.7C318.6,427.4,319.4,426.3,319.2,425.1z"/>
   <path fill="#FFFFFF" d="M319,436.5l-10,1.7l0.7,4l10-1.7c1.1-0.2,1.9-1.3,1.7-2.3C321.2,437.1,320.1,436.4,319,436.5z"/>
   <path fill="#FFFFFF" d="M321.2,449.5l-10,1.7l0.7,4l10-1.7c1.1-0.2,1.9-1.3,1.7-2.3C323.3,450.1,322.2,449.3,321.2,449.5z"/>
   <path fill="#FFFFFF" d="M295.1,485.9c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L295.1,485.9z"/>
   <path fill="#FFFFFF" d="M282.1,488c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L282.1,488z"/>
   <path fill="#FFFFFF" d="M269.1,490.2c0.2,1.1,1.3,1.9,2.3,1.7s1.9-1.3,1.7-2.3l-1.7-10l-4,0.7L269.1,490.2z"/>
   <path fill="#FFFFFF" d="M235.1,466c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C235.7,463.7,234.9,464.8,235.1,466z"/>
   <path fill="#FFFFFF" d="M233,452.8c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C233.6,450.7,232.8,451.8,233,452.8z"/>
   <path fill="#FFFFFF" d="M230.8,439.7c0.2,1.1,1.3,1.9,2.3,1.7l10-1.7l-0.7-4l-10,1.7C231.4,437.7,230.6,438.6,230.8,439.7z"/>
   <path fill="#FFFFFF" d="M259.2,405.2c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L259.2,405.2z"/>
   <path fill="#FFFFFF" d="M272.2,403c-0.2-1.1-1.3-1.9-2.3-1.7c-1.1,0.2-1.9,1.3-1.7,2.3l1.7,10l4-0.7L272.2,403z"/>
   <path fill="#FFFFFF" d="M285.2,400.9c-0.2-1.1-1.3-1.9-2.3-1.7s-1.9,1.3-1.7,2.3l1.7,10l4-0.7L285.2,400.9z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M522.7,422.4l-11.7-20.3c-0.6-1.1-2-1.5-3.1-0.8l-20.3,11.7c-1.1,0.6-1.5,2-0.8,3.1l11.7,20.3
       c0.6,1.1,2,1.5,3.1,0.8l20.3-11.7C522.9,424.9,523.3,423.5,522.7,422.4z M503,429c-0.6,0.3-1.3,0.1-1.7-0.4l-6.3-10.9
       c-0.3-0.6-0.1-1.3,0.4-1.7l10.9-6.3c0.6-0.3,1.3-0.1,1.7,0.4l6.3,10.9c0.3,0.6,0.1,1.3-0.4,1.7L503,429z"/>
   <path fill="#FFFFFF" d="M521.5,435.2c0.5-0.3,0.6-0.9,0.4-1.4l-2.5-4.4l-1.7,1l2.5,4.4C520.4,435.4,521,435.5,521.5,435.2z"/>
   <path fill="#FFFFFF" d="M516.2,437.1l-2.5-4.4l-1.7,1l2.5,4.4c0.3,0.5,0.9,0.6,1.4,0.4C516.3,438.3,516.5,437.6,516.2,437.1z"/>
   <path fill="#FFFFFF" d="M510.5,440.4L508,436l-1.7,1l2.5,4.4c0.3,0.5,0.9,0.6,1.4,0.4C510.6,441.5,510.8,440.9,510.5,440.4z"/>
   <path fill="#FFFFFF" d="M489.1,434.7c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l4.4-2.5l-1-1.7L489.1,434.7z"/>
   <path fill="#FFFFFF" d="M485.9,429.1c-0.5,0.3-0.6,0.9-0.4,1.4s0.9,0.6,1.4,0.4l4.4-2.5l-1-1.7L485.9,429.1z"/>
   <path fill="#FFFFFF" d="M482.6,423.4c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.9,0.6,1.4,0.4l4.4-2.5l-1-1.7L482.6,423.4z"/>
   <path fill="#FFFFFF" d="M487.9,403.4c-0.5,0.3-0.6,0.9-0.4,1.4l2.5,4.4l1.7-1l-2.5-4.4C489,403.3,488.4,403.1,487.9,403.4z"/>
   <path fill="#FFFFFF" d="M493.6,400.1c-0.5,0.3-0.6,0.9-0.4,1.4l2.5,4.4l1.7-1l-2.5-4.4C494.7,400,494,399.8,493.6,400.1z"/>
   <path fill="#FFFFFF" d="M499.3,396.8c-0.5,0.3-0.6,0.9-0.4,1.4l2.5,4.4l1.7-1l-2.5-4.4C500.3,396.7,499.7,396.5,499.3,396.8z"/>
   <path fill="#FFFFFF" d="M520.3,403.9c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-4.4,2.5l1,1.7L520.3,403.9z"/>
   <path fill="#FFFFFF" d="M523.5,409.6c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-4.4,2.5l1,1.7L523.5,409.6z"/>
   <path fill="#FFFFFF" d="M526.8,415.2c0.5-0.3,0.6-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.4l-4.4,2.5l1,1.7L526.8,415.2z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M509.9,226.3c-2.7,0-5-1.9-5.5-4.7l-7.7-46.6c-0.2-1.5,0.1-3,1-4.2s2.2-2,3.7-2.3l46.6-7.7
       c1.5-0.2,3,0.1,4.2,1c1.2,0.9,2,2.2,2.3,3.7l7.7,46.6c0.5,3.1-1.5,5.9-4.6,6.5l-46.6,7.7C510.5,226.3,510.2,226.3,509.9,226.3z
        M548.2,162.8l-46.6,7.7c-1,0.2-1.8,0.7-2.4,1.5c-0.6,0.8-0.8,1.7-0.6,2.7l7.7,46.6c0.3,2,2.2,3.3,4.2,3l46.6-7.7
       c2-0.3,3.3-2.2,3-4.2l-7.7-46.6c-0.2-1-0.7-1.8-1.5-2.4C550.2,162.9,549.2,162.7,548.2,162.8L548.2,162.8z M518.9,211.7
       c-1.7,0-3.1-1.2-3.4-2.9l-4.2-25.2c-0.3-1.9,1-3.6,2.8-4l25.2-4.2c0.9-0.1,1.8,0.1,2.6,0.6c0.7,0.5,1.2,1.3,1.4,2.2l4.2,25.2
       c0.2,0.9-0.1,1.8-0.6,2.6c-0.5,0.7-1.3,1.2-2.2,1.4l-25.2,4.2C519.3,211.7,519.1,211.7,518.9,211.7z M539.9,177.4
       c-0.1,0-0.2,0-0.2,0l-25.2,4.2c-0.4,0.1-0.7,0.3-0.9,0.6c-0.2,0.3-0.3,0.7-0.2,1.1l4.2,25.2c0.1,0.8,0.9,1.3,1.7,1.2l25.2-4.2
       c0.4-0.1,0.7-0.3,0.9-0.6s0.3-0.7,0.2-1.1l-4.2-25.2c-0.1-0.4-0.3-0.7-0.6-0.9C540.5,177.5,540.2,177.4,539.9,177.4z"/>
   <path fill="#FFFFFF" d="M558.9,178.3l-1-6l11-1.8c1.6-0.3,3.2,0.9,3.5,2.5v0c0.2,0.9-0.1,1.8-0.6,2.5c-0.5,0.6-1.2,1-1.9,1
       L558.9,178.3z M560.2,173.9l0.3,2l9.2-1.5c0.3,0,0.4-0.2,0.5-0.3c0.2-0.2,0.3-0.6,0.2-0.9l0,0c-0.1-0.5-0.7-0.9-1.2-0.9
       L560.2,173.9z"/>
   <path fill="#FFFFFF" d="M561,191.4l-1-6l11-1.8c0.8-0.1,1.6,0.1,2.3,0.5c0.7,0.5,1.1,1.2,1.2,2c0.1,0.8-0.1,1.6-0.5,2.3
       s-1.2,1.1-2,1.2L561,191.4z M562.3,187.1l0.3,2l9.1-1.5c0.3,0,0.5-0.2,0.7-0.4s0.2-0.5,0.2-0.8c0-0.3-0.2-0.5-0.4-0.7
       c-0.2-0.2-0.5-0.2-0.8-0.2l0,0L562.3,187.1z"/>
   <path fill="#FFFFFF" d="M563.2,204.4l-1-6l11-1.8c1.6-0.3,3.2,0.9,3.5,2.5c0.3,1.6-0.9,3.2-2.5,3.5L563.2,204.4z M564.5,200l0.3,2
       l9.1-1.5c0.5-0.1,0.9-0.7,0.9-1.2c-0.1-0.5-0.7-0.9-1.2-0.9L564.5,200z"/>
   <path fill="#FFFFFF" d="M549.4,236.6c-1.4,0-2.8-1.1-3-2.5v0l-1.8-11l6-1l1.8,11c0.1,0.8-0.1,1.6-0.5,2.3s-1.2,1.1-2,1.2
       C549.7,236.6,549.5,236.6,549.4,236.6z M548.3,233.8c0.1,0.5,0.7,0.9,1.2,0.9c0.3,0,0.5-0.2,0.7-0.4s0.2-0.5,0.2-0.8l-1.5-9.1
       l-2,0.3L548.3,233.8z"/>
   <path fill="#FFFFFF" d="M536.4,238.8c-0.6,0-1.3-0.2-1.8-0.6c-0.7-0.5-1.1-1.2-1.2-2l-1.8-11l6-1l1.8,11c0.3,1.6-0.9,3.2-2.5,3.5
       C536.7,238.8,536.6,238.8,536.4,238.8z M533.8,226.9l1.5,9.1c0,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.8,0.2
       c0.5-0.1,0.9-0.7,0.9-1.2l-1.5-9.1L533.8,226.9z"/>
   <path fill="#FFFFFF" d="M523.4,240.9c-0.6,0-1.3-0.2-1.8-0.6c-0.7-0.5-1.1-1.2-1.2-2l-1.8-11l6-1l1.8,11c0.1,0.8-0.1,1.6-0.5,2.3
       c-0.5,0.7-1.2,1.1-2,1.2C523.7,240.9,523.6,240.9,523.4,240.9z M520.9,229l1.5,9.1c0,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.8,0.2
       c0.3,0,0.5-0.2,0.7-0.4s0.2-0.5,0.2-0.8l-1.5-9.1L520.9,229z"/>
   <path fill="#FFFFFF" d="M489.4,216.7c-0.6,0-1.3-0.2-1.8-0.6c-0.7-0.5-1.1-1.2-1.2-2l0,0c-0.2-0.9,0.1-1.8,0.6-2.5
       c0.5-0.6,1.2-1,1.9-1l11-1.8l1,6l-11,1.8C489.7,216.7,489.6,216.7,489.4,216.7z M498.3,211.1l-9.2,1.5c-0.2,0-0.4,0.2-0.5,0.3
       c-0.2,0.2-0.3,0.6-0.2,0.9l0,0c0,0.3,0.2,0.5,0.4,0.7s0.5,0.2,0.8,0.2l9.1-1.5L498.3,211.1z"/>
   <path fill="#FFFFFF" d="M487.2,203.6c-0.6,0-1.3-0.2-1.8-0.6c-0.7-0.5-1.1-1.2-1.2-2l0,0c-0.1-0.8,0.1-1.6,0.5-2.3
       c0.5-0.7,1.2-1.1,2-1.2l11-1.8l1,6l-11,1.8C487.5,203.6,487.4,203.6,487.2,203.6z M496.1,198l-9.1,1.5c-0.3,0-0.5,0.2-0.7,0.4
       s-0.2,0.5-0.2,0.8l0,0c0,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.8,0.2l9.1-1.5L496.1,198z"/>
   <path fill="#FFFFFF" d="M485.1,190.5c-0.6,0-1.3-0.2-1.8-0.6c-0.7-0.5-1.1-1.2-1.2-2l0,0c-0.2-1.5,0.8-3,2.4-3.5l0.1,0l11-1.8l1,6
       l-11,1.8C485.4,190.4,485.2,190.5,485.1,190.5z M484,187.6c0,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.8,0.2l9.1-1.5l-0.3-2l-9,1.5
       C484.4,186.6,483.9,187,484,187.6L484,187.6z"/>
   <path fill="#FFFFFF" d="M508.2,165l-1.8-11c-0.3-1.6,0.9-3.2,2.5-3.5c1.6-0.3,3.2,0.9,3.5,2.5l0,0l1.8,11L508.2,165z M509.4,152.5
       c0,0-0.1,0-0.1,0c-0.5,0.1-0.9,0.7-0.9,1.2l1.5,9.1l2-0.3l-1.5-9.1C510.4,152.9,509.9,152.5,509.4,152.5z"/>
   <path fill="#FFFFFF" d="M521.2,162.9l-1.8-11c-0.3-1.6,0.9-3.2,2.5-3.5c1.6-0.3,3.2,0.9,3.5,2.5v0l1.8,11L521.2,162.9z
        M522.4,150.3c0,0-0.1,0-0.1,0c-0.5,0.1-0.9,0.7-0.9,1.2l1.5,9.1l2-0.3l-1.5-9.1C523.3,150.7,522.9,150.3,522.4,150.3z"/>
   <path fill="#FFFFFF" d="M534.2,160.7l-1.8-11c-0.1-0.8,0.1-1.6,0.5-2.3c0.5-0.7,1.2-1.1,2-1.2c0.8-0.1,1.6,0.1,2.3,0.5
       c0.7,0.5,1.1,1.2,1.2,2l1.8,11L534.2,160.7z M535.4,148.2c0,0-0.1,0-0.1,0c-0.3,0-0.5,0.2-0.7,0.4s-0.2,0.5-0.2,0.8l1.5,9.1l2-0.3
       l-1.5-9.1c0-0.3-0.2-0.5-0.4-0.7C535.8,148.3,535.6,148.2,535.4,148.2z"/>
</g>
<g>
   <path fill="#FFFFFF" d="M554.6,111.5c-0.9,0-1.7-0.3-2.4-0.8l-25.3-18.6c-0.9-0.6-1.5-1.6-1.6-2.7c-0.2-1.1,0.1-2.1,0.8-3
       l18.6-25.3c0.6-0.9,1.6-1.5,2.7-1.6c1.1-0.2,2.1,0.1,3,0.7l25.3,18.6c1.8,1.3,2.2,3.8,0.9,5.7l-18.6,25.3c-0.6,0.9-1.6,1.5-2.7,1.6
       C555,111.5,554.8,111.5,554.6,111.5z M547.8,61.4c-0.1,0-0.2,0-0.3,0c-0.5,0.1-1,0.4-1.4,0.8l-18.6,25.3c-0.3,0.5-0.5,1-0.4,1.5
       c0.1,0.5,0.4,1,0.8,1.4l25.3,18.6c0.5,0.3,1,0.5,1.5,0.4c0.5-0.1,1-0.4,1.4-0.8l18.6-25.3c0.7-0.9,0.5-2.2-0.4-2.9L549,61.8
       C548.7,61.6,548.3,61.4,547.8,61.4z M553,99.9c-0.5,0-1.1-0.2-1.5-0.5l-13.7-10c-1.2-0.9-1.4-2.5-0.6-3.7l10-13.7
       c0.9-1.2,2.5-1.4,3.7-0.6l13.7,10c0.6,0.4,0.9,1,1,1.7c0.1,0.7-0.1,1.4-0.5,2l-10,13.7C554.7,99.5,553.9,99.9,553,99.9z M549.4,73
       c-0.2,0-0.4,0.1-0.5,0.3l-10,13.7c-0.2,0.3-0.1,0.7,0.1,0.9l13.7,10c0.3,0.2,0.7,0.1,0.9-0.1l10-13.7c0.1-0.1,0.1-0.3,0.1-0.5
       c0-0.2-0.1-0.3-0.3-0.4l-13.7-10C549.6,73,549.5,73,549.4,73z"/>
   <path fill="#FFFFFF" d="M579.4,97.5c-0.5,0-1-0.2-1.4-0.5l-6.2-4.6l2.8-3.8l6.3,4.6c0.5,0.4,0.8,0.9,0.9,1.5
       c0.1,0.6-0.1,1.3-0.4,1.7l0,0c-0.4,0.6-1,0.9-1.7,1C579.5,97.5,579.4,97.5,579.4,97.5z M574.5,92l4.7,3.5c0,0,0,0,0.1,0
       c0.1,0,0.2-0.1,0.3-0.2v0c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.2l-4.7-3.4L574.5,92z"/>
   <path fill="#FFFFFF" d="M574.1,104.6c-0.5,0-1-0.1-1.4-0.4l-6.3-4.6l2.8-3.8l6.3,4.6c0.5,0.4,0.8,0.9,0.9,1.5
       c0.1,0.6-0.1,1.3-0.4,1.7C575.6,104.2,574.9,104.6,574.1,104.6z M569.3,99.1l4.7,3.4c0.1,0.1,0.4,0.1,0.5-0.1
       c0-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.2v0l-4.7-3.4L569.3,99.1z"/>
   <path fill="#FFFFFF" d="M569,111.6c-0.5,0-1-0.1-1.4-0.4l-6.3-4.6l2.8-3.8l6.3,4.6c1,0.8,1.3,2.3,0.5,3.3
       C570.4,111.3,569.7,111.6,569,111.6z M564.1,106.2l4.7,3.4c0.1,0.1,0.4,0.1,0.5-0.1c0.1-0.1,0.1-0.4-0.1-0.5v0l-4.7-3.4
       L564.1,106.2z"/>
   <path fill="#FFFFFF" d="M541.5,115.9c-0.5,0-1-0.2-1.4-0.4c-0.5-0.4-0.8-0.9-0.9-1.5c-0.1-0.6,0.1-1.3,0.4-1.7l4.6-6.3l3.8,2.8
       l-4.6,6.3c-0.4,0.5-0.9,0.8-1.5,0.9C541.7,115.9,541.6,115.9,541.5,115.9z M544.6,108.7l-3.4,4.7c0,0.1-0.1,0.2-0.1,0.3
       c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.1l3.4-4.7L544.6,108.7z"/>
   <path fill="#FFFFFF" d="M534.4,110.7c-0.5,0-1-0.1-1.4-0.4c-1-0.8-1.3-2.3-0.5-3.3l4.6-6.3l3.8,2.8l-4.6,6.3
       C535.9,110.4,535.1,110.7,534.4,110.7z M537.5,103.5l-3.4,4.7c-0.1,0.1-0.1,0.4,0.1,0.5c0.1,0.1,0.4,0.1,0.5-0.1l3.4-4.7
       L537.5,103.5z"/>
   <path fill="#FFFFFF" d="M527.3,105.5c-0.5,0-1-0.2-1.4-0.4c-0.5-0.4-0.8-0.9-0.9-1.5c-0.1-0.6,0.1-1.3,0.4-1.7l4.6-6.3l3.8,2.8
       l-4.6,6.3c-0.4,0.5-0.9,0.8-1.5,0.9C527.6,105.5,527.5,105.5,527.3,105.5z M530.5,98.3l-3.4,4.7c0,0.1-0.1,0.2-0.1,0.3
       c0,0.1,0.1,0.2,0.1,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2-0.1,0.2-0.1l3.4-4.7L530.5,98.3z"/>
   <path fill="#FFFFFF" d="M527.9,82.3l-6.3-4.6c-1-0.8-1.3-2.3-0.5-3.3l0,0c0.4-0.6,1-0.9,1.7-1c0.6-0.1,1.2,0.1,1.6,0.5l6.2,4.6
       L527.9,82.3z M522.8,75.6c-0.1,0.1-0.1,0.4,0.1,0.5l4.7,3.4l0.4-0.6l-4.7-3.5c0,0,0,0-0.1,0C523,75.4,522.8,75.5,522.8,75.6z"/>
   <path fill="#FFFFFF" d="M533.2,75.1l-6.3-4.6c-1-0.8-1.3-2.3-0.5-3.3c0.8-1,2.3-1.3,3.3-0.5l6.3,4.6L533.2,75.1z M528.3,68.3
       c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.4,0.1,0.5l4.7,3.4l0.4-0.6l-4.7-3.4C528.4,68.3,528.4,68.3,528.3,68.3z"/>
   <path fill="#FFFFFF" d="M538.4,68l-6.3-4.6c-1-0.8-1.3-2.3-0.5-3.3c0.7-1,2.1-1.2,3.2-0.6l0.1,0.1l6.3,4.6L538.4,68z M533.5,61.2
       c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.4,0.1,0.5l4.7,3.4l0.4-0.6l-4.6-3.4C533.7,61.2,533.6,61.2,533.5,61.2z"/>
   <path fill="#FFFFFF" d="M558.3,65l-3.8-2.8l4.6-6.3c0.8-1,2.3-1.3,3.3-0.5c1,0.8,1.3,2.3,0.5,3.3L558.3,65z M557.3,61.8l0.6,0.4
       l3.4-4.7c0.1-0.1,0.1-0.4-0.1-0.5c-0.1-0.1-0.4-0.1-0.5,0.1L557.3,61.8z"/>
   <path fill="#FFFFFF" d="M565.3,70.2l-3.8-2.8l4.6-6.3c0.4-0.5,0.9-0.8,1.5-0.9c0.6-0.1,1.3,0.1,1.7,0.4c0.5,0.4,0.8,0.9,0.9,1.5
       c0.1,0.6-0.1,1.3-0.4,1.7L565.3,70.2z M564.3,66.9l0.6,0.4l3.4-4.7c0-0.1,0.1-0.2,0.1-0.3s-0.1-0.2-0.1-0.2c-0.1,0-0.2-0.1-0.3-0.1
       c-0.1,0-0.2,0.1-0.2,0.1L564.3,66.9z"/>
   <path fill="#FFFFFF" d="M572.4,75.3l-3.8-2.8l4.6-6.3c0.8-1,2.3-1.3,3.3-0.5c0.5,0.4,0.8,0.9,0.9,1.5s-0.1,1.3-0.4,1.7L572.4,75.3z
        M571.4,72.1l0.6,0.4l3.4-4.7c0-0.1,0.1-0.2,0.1-0.3s-0.1-0.2-0.1-0.2c-0.1-0.1-0.4-0.1-0.5,0.1L571.4,72.1z"/>
</g>
</svg>`
  });
  