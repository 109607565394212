import Vue from 'vue'
import App from './App.vue'
import router from './router'
import  { store } from './store/index'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueYoutube from 'vue-youtube'

Vue.config.productionTip = false

var url = new URL(window.location.href);
var recipeId = url.searchParams.get("id");
console.log(recipeId);

console.log(url);

Vue.use(VueResource);
Vue.use(VueCookies);
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueYoutube);

import './assets/main.css'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
