<template>
    <div class="text-center">
        <v-row align="center">
            <v-col align="center" style="display: flex; justify-content: center ">
                <v-img
                    alt="לוגו"
                    class="logo-size-main shrink mr-2"
                    contain
                    src="../assets/logo-white.svg"
                    transition="scale-transition"
                    style="align-self: center"
                />

                <div class="logo-text-main" style="align-self: center">מבשלים היי-טק</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col style="" align="center">
                <!-- <iframe width="1280" height="720" src="https://www.youtube.com/embed/36jsXjkDg0Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                <div style="max-width: 640px; position: relative">
                    <div class="iframe-container">
                        <youtube style="border-radius: 15px;" video-id="36jsXjkDg0Y" :resize="true" :fitParent="true" :player-vars="{controls: 1}"  ref="youtube"></youtube>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Home",
    mounted() {
        this.$vuetify.rtl = true;
    },    
    data: () => ({
        colors: ["green", "purple", "orange", "indigo", "red"],
        rating: 0,
        cookieFilled: "$vuetify.icons.tovi_cookie_filled",
        cookieOutline: "$vuetify.icons.tovi_cookie_outline",
        
    }),
    methods: {
        genColor(i) {
            return this.colors[i];
        },
    },
};
</script>
