<template>
    <v-app>
        <v-app-bar app color="orange" dark v-if="showAppBar">
            <div class="d-flex align-center">
                <v-img
                    alt="לוגו"
                    class="shrink mr-2"
                    contain
                    src="./assets/logo-white.svg"
                    transition="scale-transition"
                    width="40"
                />
            </div>
            <div class="logo-text">מבשלים היי-טק</div>
            <v-spacer></v-spacer>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",
    mounted() {
        var self = this;
        this.$vuetify.rtl = true;
        console.log(this.$router.app._route.name);
        this.$nextTick(() => {
            if (this.$router.app._route.name != "Home") {
                self.showAppBar = true;
            }
        });
    },
    data: () => ({
        showAppBar: false
    }),
    watch: {
        $route(to, from) {
            console.log("--->");
            console.log(to);
            console.log("---");
            console.log(from);
        },
    },
};
</script>
