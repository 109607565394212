import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Recipe/:id',
    name: 'Recipe',
    component: () => import('../views/Recipe.vue')
  },
  {
    path: '/Viewer/:id',
    name: 'Viewer',
    component: () => import('../views/Viewer.vue')
  }  
]

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
